import {Injectable} from "@angular/core";


@Injectable({providedIn: "root"})
export default class GTMService {
    pushEvent(action) {
        const eventData = {
            'event': 'ga_event',
            'eventCategory': 'user click',
            'eventAction': action,
            'eventLabel': action
        }
        //@ts-ignore
        if (typeof dataLayer !== 'undefined') dataLayer.push(eventData);
    }
}
