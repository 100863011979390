import { Component, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from  '@services/upload.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import GTMService from '@app/services/gtm.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  constructor(
    private router:Router,
    public GTMService: GTMService
  ) {}

  
}