<section class="container flex-container column screen-wrapper align-top">
    <div class="flex-container column align-center">
      <h1 class="title custom-color-2">Merge PDF</h1>
      <p class="subtitle">The simplest method for combining PDF files</p>
    </div>
    <!-- start choose file screen -->
    <div class="pronto flex-container column align-center">
      <ngx-dropzone class="flex-container column" (change)="onDrop($event)" disableClick [ngStyle]="{'display': (progress == 'start') ? 'flex' : 'none'}">
        <img class="main-image" src="/images/files.png">
        <div class="flex-container column align-center">
          <button class="btn flex-container row align-center" (click)="onClick()">
              <img src="/images/add.svg" alt="document">
              <span>Choose Files</span>
          </button>
          <small>or drop files here</small>
        </div>
        <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
      </ngx-dropzone>
    </div>
    <!-- end choose file screen -->

    <!--  start loading error -->
    <div class="pronto__loading column" [ngStyle]="{'display': (progress == 'loading' || progress == 'finished') ? 'flex' : 'none'}">
      <div class="flex-container row line-item"  *ngFor="let file of files; let i = index">
        <div class="tbl-file-title">
            <img src="/images/add.svg" alt="document" />
            <span>{{file.name}}</span>
        </div>
        <div class="tbl-icon flex-container row">
          <img src="/images/merge-dark.svg" alt="document">
          <p>Merge Files</p>
        </div>
        <div class="tbl-last flex-container row">
          <img *ngIf="!file.status && !inProgress" src="/images/remove.svg" alt="remove" (click)='removeItem(i)'>
          <span *ngIf="file.status == 'success' && inProgress" class="loading">Progress...</span>
          <span *ngIf="file.status == 'success' && !inProgress" class="finished">Finished</span>
          <span *ngIf="file.status == 'failed'" class="failed">Failed</span>
        </div>
      </div>
      <div class="row justify" [ngStyle]="{'display': (progress == 'loading') ? 'flex' : 'none'}">
        <button [ngClass]="{'flex-container':true, 'btn':true, 'row':true, 'align-center':true, 'disabled':inProgress, 'add-more' : true}" (click)="onClick()">
          <span>Add More Files</span>
        </button>
        <button [ngClass]="{'flex-container':true, 'btn':true, 'row':true, 'align-center':true, 'progress-button':true, 'disabled':inProgress}" (click)="uploadFiles()">
          <img src="/images/merge-white.svg" alt="document">
          <span>Merge</span>
        </button>
      </div>
      <div class="row line-btns" [ngStyle]="{'display': (progress == 'finished') ? 'flex' : 'none'}">
        <button class="flex-container btn row align-center reload-btn" (click)="reloadScreen()">
          <img src="/images/undo.svg" alt="document">
          <span>Start Over</span>
        </button>
      </div>
    </div>
    <!--  end loafing error -->

    <!--  start file type error -->
    <div class="flex-container column align-center pronto__unvalid pronto-error" [ngStyle]="{'display': (progress == 'alert') ? 'flex' : 'none'}">
      <img src="/images/error-image.png"> 
<p class="error-title"> ERROR </p>
      <p>This file type you have selected is not supported</p>
      <button class="btn undo-btn flex-container row align-center" (click)="clearScreen('start')">
        <img src="/images/undo.svg" alt="document">
        <span>Start Over</span>
      </button>
    </div>
    <!--  end alert error -->

    <!--  start custom error -->
    <div class="flex-container column align-center pronto__unvalid pronto-error" [ngStyle]="{'display': (progress == 'error') ? 'flex' : 'none'}">
      <img src="/images/error-image.png"> 
<p class="error-title"> ERROR </p>
      <p>Error occurred. Please, try again</p>
      <button class="btn undo-btn flex-container row align-center" (click)="clearScreen('start')">
        <img src="/images/undo.svg" alt="document">
        <span>Start Over</span>
      </button>
    </div>
    <!--  end alert error -->
</section>