import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '@modules/app-routing.module';
import { AppComponent } from '@components/app/app.component';
import { FooterComponent } from '@components/footer/footer.component';
import { ButtonComponent } from '@components/button/button.component';
import { HeaderComponent } from '@components/header/header.component';
import { ConvertComponent } from '@components/convert/convert.component';
import { MainComponent } from '@components/main/main.component';
import { OptimizeComponent } from '@components/optimize/optimize.component';
import { MergeComponent } from '@components/merge/merge.component';
import { UploadService } from '@services/upload.service';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
  declarations: [
    AppComponent,
    ConvertComponent,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    MainComponent,
    OptimizeComponent,
    MergeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxDropzoneModule,
  ],
  providers: [UploadService],
  bootstrap: [AppComponent]
})
export class AppModule { }
