import { Component, OnInit } from '@angular/core';
import GTMService from '@app/services/gtm.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  constructor( public GTMService: GTMService) { }

  ngOnInit(): void {
  }

}
